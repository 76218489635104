<!--
 * @Author       : JiangChao
 * @Date         : 2024-08-22 09:11:58
 * @LastEditors  : JiangChao
 * @LastEditTime : 2025-04-02 10:27:37
 * @Description  : 创建告警规则-有模板
-->
<template>
  <div class="container">
    <div class="title font-heavy">{{ $t("new_alert_rules") }}</div>
    <div class="sub-title">
      {{ $t("alert_tip_key_for_create") }}
    </div>
    <div class="basic-settings font-heavy">
      <div class="title">
        <div>{{ $t("basic_settings") }}</div>
        <img src="../../assets/ic_close.png" alt="" @click="backToCreate" />
      </div>

      <el-form ref="form" :model="form" :rules="formRules" label-width="120px">
        <div class="sub-title">{{ $t("scene_name") }}</div>
        <el-input v-model="form.name" class="input-style"></el-input>

        <el-divider></el-divider>
        <!-- <div class="upload-box"> -->
        <!-- <div class="sub-title">{{ $t("scene_picture") }}</div> -->
        <!-- <el-upload
            ref="upload"
            action=""
            :on-change="getFile"
            :limit="1"
            :auto-upload="false"
            :show-file-list="false"
          >
            <img
              src="../../assets/ic_upload.png"
              alt=""
              width="30"
              height="30"
              style="margin-left: 10px; margin-right: 10px"
            />
          </el-upload> -->
        <!-- <span>选择图片上传,最多上传一张图片</span> -->
        <!-- </div> -->
        <!-- <div style="display: flex">
          <div
            v-for="(item, index) in bgImgList"
            :key="index"
            class="bg-img-box"
            :style="{ width: calculateWidth(bgImgList.length) + '%' }"
          >
            <img
              :src="item.src"
              :alt="item.id"
              :title="item.id"
              @click="showImagePreview(item.src)"
              height="50"
            />
            <input
              type="radio"
              :value="item.id"
              v-model="selectedId"
              @change="onSelectionChange"
            />
          </div>
        </div> -->
        <!-- 图片预览 -->
        <!-- <transition name="fade">
          <div
            v-if="isPreviewVisible"
            class="preview-modal"
            @click="handleClickOutside"
          >
            <img
              :src="previewImageUrl"
              alt="preview"
              style="max-width: 100%; height: auto"
              @click.stop=""
            />
          </div>
        </transition> -->
        <!-- <div class="image-container">
          <img class="bg" v-if="base64Image" :src="base64Image" alt="" />
          <img
            class="bg"
            v-if="!base64Image"
            src="../../assets/ic_default.png"
            alt=""
          />
        </div> -->
        <!-- <el-divider></el-divider>
        <span class="sub-title">{{ $t("select_loggers") }}</span>

        <div class="select-device">
          <div class="select-box" @click="selectDevice">
            <span> {{ selectedDevices }}</span>
            <img src="../../assets/ic_blue_arrow.png" alt="" srcset="" />
          </div>
        </div>

        <div class="sub-tip">
          {{ $t("select_tip") }}
        </div> -->
        <!-- <el-divider></el-divider> -->
        <div class="sub-title">{{ $t("alert_rules") }}</div>
        <div class="sub-tip">
          {{ $t("alert_tip") }}
        </div>
        <el-button type="primary" class="add-rule" @click="addRule"
          >+{{ $t("add_new_alert_rule") }}
        </el-button>

        <el-form-item
          v-for="(rule, index) in form.detailList"
          :key="rule.key"
          :prop="'detailList.' + index + '.value'"
        >
          <div class="rule-box">
            <div class="selects-container">
              <el-select
                v-model="rule.type"
                class="select-item custom-placeholder"
                @change="onTypeChange(rule)"
                :disabled="isEditDisabled(index)"
                placeholder="Select rule type"
              >
                <el-option
                  :label="item.name"
                  :value="item.value"
                  v-for="item in alarmType"
                  :key="item.value"
                ></el-option>
              </el-select>
              <!-- 第二个 <el-select> 根据 isFirstSelectChosen 和 isNew 的值来决定是否禁用 -->
              <el-select
                v-model="rule.source"
                class="select-item custom-placeholder"
                @change="onSourceChange(rule)"
                :disabled="
                  !isFirstSelectChosen[rule.key] || isEditDisabled(index)
                "
                placeholder="Select data type"
              >
                <el-option
                  :label="item.name"
                  :value="item.value"
                  v-for="(item, index) in alarmSensor"
                  :key="index"
                ></el-option>
              </el-select>
              <!-- <el-select
                v-model="rule.conditions"
                class="select-item"
                placeholder="Select condition"
              >
                <el-option
                  :label="item.name"
                  :value="item.value"
                  v-for="item in alarmConditions"
                  :key="item.value"
                ></el-option>
              </el-select>
              <el-input
                v-model.number="rule.value"
                class="input-item"
              ></el-input> -->
              <el-input
                class="input-item custom-placeholder"
                placeholder="less than"
                disabled
                v-if="!showAfterOrSelector(rule)"
              >
              </el-input>

              <el-input
                v-model.number="rule.min"
                class="input-item"
                v-if="!showAfterOrSelector(rule)"
              ></el-input>
              <div v-if="showOrTextSelector(rule)">OR</div>
              <el-input
                class="input-item custom-placeholder"
                placeholder="more than"
                disabled
                v-if="!showBeforeOrSelector(rule)"
              >
              </el-input>

              <el-input
                v-model.number="rule.max"
                class="input-item"
                v-if="!showBeforeOrSelector(rule)"
              ></el-input>
              <el-input
                class="input-item"
                v-model="rule.unit"
                disabled
                v-if="!showTemperatureUnit(rule)"
              >
                {{ setSelectedTempType }}
              </el-input>
              <div v-if="showDurationSelector(rule)">
                For more than
                <el-input
                  v-model.number="rule.duration"
                  class="input-item"
                ></el-input>
                <el-input
                  placeholder="Minutes"
                  class="input-item custom-placeholder"
                  disabled
                  style="margin-left: 8px"
                ></el-input>
              </div>
            </div>
            <img
              @click.prevent="removeRule(rule)"
              src="../../assets/ic_cross.png"
              alt=""
            />
          </div>
        </el-form-item>

        <!-- <div class="sub-tip">
          {{ $t("delay_tip") }}
        </div> -->

        <div class="sub-title">{{ $t("alert_reception_time") }}</div>

        <el-radio-group v-model="alldayRadio" @change="onChangeTime">
          <div class="radio">
            <el-radio label="allday" style="min-width: 100px">{{
              $t("all_day")
            }}</el-radio>

            <div class="custom-time">
              <el-radio label="custom" style="min-width: 100px"
                >{{ $t("custom") }}(8:00 to 21:00)</el-radio
              >
            </div>

            <div v-if="!isAllDay" style="margin-left: 20px">
              <el-time-select
                v-model="form.config.startMin"
                :start="8"
                :end="20"
                :step="1"
                :picker-options="{
                  start: '08:00',
                  step: '01:00',
                  end: '21:00',
                }"
                placeholder="Start Time"
                style="width: 180px; margin-right: 10px"
              ></el-time-select>
              <span>to</span>
              <el-time-select
                v-model="form.config.endMin"
                :start="8"
                :end="20"
                :step="1"
                :picker-options="{
                  start: '08:00',
                  step: '01:00',
                  end: '21:00',
                }"
                placeholder="End Time"
                style="width: 180px; margin-left: 10px"
              ></el-time-select>
            </div>
          </div>
        </el-radio-group>

        <div class="sub-title">{{ $t("alert_notification_channels") }}</div>

        <div class="checkbox">
          <el-checkbox @change="onChangeInd">{{
            $t("app_notification")
          }}</el-checkbox>
          <el-checkbox @change="onChangeEmailInd">{{
            $t("Email")
          }}</el-checkbox>
          <!-- <el-input
            v-if="emailInputVisible"
            placeholder="Enter email address"
            v-model="form.config.email"
          ></el-input> -->
        </div>

        <el-button type="primary" class="create-btn" @click="submitForm">
          {{ $t("create") }}</el-button
        >
      </el-form>
    </div>
    <AddDeviceToNewAlertDialog
      ref="deviceRef"
      @ok="getSelectedDeviceIds"
    ></AddDeviceToNewAlertDialog>
  </div>
</template>

<script>
import { selectApi, addSceneApi, getSceneAlarmConfigApi } from "@/api/api.js";
import AddDeviceToNewAlertDialog from "./modal/AddDeviceToNewAlertDialog.vue";

export default {
  name: "CreateSceneWithTemplate",
  data() {
    return {
      sensorList: [],
      form: {
        name: "",
        deviceIdList: [], //设备id列表
        detailList: [], //配置详情
        //配置主信息
        config: {
          emailInd: "", //是否进行邮件告警 0：不发送 1：发送
          appInd: "", //是否进行app推送告警 0：不推送 1：推送
          startMin: "", // 开始时间
          endMin: "", // 结束时间
          email: "", //告警邮箱，多个逗号隔开
        },
      },
      alarmType: [],
      alarmSensor: [],
      alarmConditions: [],
      dialogFormVisible: false,
      // dialogStyle: {
      //   top: "60px",
      //   left: "70px",
      // },
      formRules: {
        name: [
          {
            required: true,
            message: this.$t("please_enter_scene_name"),
            trigger: "blur",
          },
        ],
      },
      emailInputVisible: false,
      isAllDay: true,
      alldayRadio: "allday",
      sceneId: "",
      total: 0,
      pageSize: 10,
      pageNum: 1,
      selectedDevices: "",
      fileList: [],
      // 图片尺寸比例要求
      // aspectRatio: 23 / 183, // 设置为1:1，可以根据需要调整
      // base64Image: "",
      selectedId: "0", // 初始时没有选中的图片
      // previewImageUrl: null, // 预览图片的URL
      // isPreviewVisible: false, // 控制预览图是否可见
      // imageUrl: "",
      orgId: "",
      // bgImgList: [
      //   {
      //     src: require("@/assets/ic_default.png"),
      //     id: "0",
      //   },
      //   {
      //     src: require("@/assets/ic_default_Freezer.jpg"),
      //     id: "1",
      //   },
      //   {
      //     src: require("@/assets/ic_default_Refrigerated.jpg"),
      //     id: "2",
      //   },
      //   {
      //     src: require("@/assets/ic_default_Room-temperature.jpg"),
      //     id: "3",
      //   },
      //   {
      //     src: require("@/assets/ic_default_Contolled-room-temperature.jpg"),
      //     id: "4",
      //   },
      // ],
      isFirstSelectChosen: {}, // 用于存储每个规则的第一个选择框是否已选择
    };
  },
  methods: {
    isEditDisabled(index) {
      return this.form.detailList[index].isNew === undefined;
    },
    selectDevice() {
      this.$refs.deviceRef.showDialog();
    },

    removeRule(item) {
      const index = this.form.detailList.findIndex(
        (rule) => rule.key === item.key
      );
      if (index !== -1) {
        this.form.detailList.splice(index, 1);
      }
    },
    addRule() {
      this.form.detailList.push({
        sceneId: this.sceneId,
        type: "",
        source: "",
        // conditions: "",
        // value: "",
        min: "",
        max: "",
        unit: "",
        key: Date.now(),
        duration: "",
        isNew: true, // 设置为新数据
      });
    },
    getSceneAlarmConfig() {
      getSceneAlarmConfigApi({ sceneId: this.sceneId }).then((r) => {
        console.log("r: ", r);
        this.form.config = r.data.config;
        this.form.detailList = r.data.detailList;
        // 直接处理 unit 字段
        this.form.detailList = this.form.detailList.map((item) => {
          if (item.unit === "c" || item.unit === "C") {
            item.unit = "°C";
          } else if (item.unit === "f" || item.unit === "F") {
            item.unit = "°F";
          }
          item.source = String(item.source);
          item.type = String(item.type);
          return item;
        });
      });
    },
    getAlarmType() {
      selectApi({ type: "AlarmType" }).then((r) => {
        this.alarmType = r.data;
      });
    },
    getAlarmSensor(code) {
      selectApi({ type: "AlarmSensor", pCode: code }).then((r) => {
        this.alarmSensor = r.data;
      });
    },
    getAlarmConditions() {
      selectApi({ type: "AlarmConditions" }).then((r) => {
        this.alarmConditions = r.data;
      });
    },
    getSelectedDeviceIds(temp) {
      this.form.deviceIdList = temp;
      console.log("this.form.deviceIdList: ", this.form.deviceIdList);
      this.selectedDevices = this.form.deviceIdList.join(",");
    },
    toggleEmailInput(val) {
      console.log("val: ", val);
      this.emailInputVisible = val == true ? true : false;
    },
    onChangeInd(val) {
      console.log("onChangeInd--val: ", val);
      //是否进行app推送告警 0：不推送 1：推送
      this.form.config.appInd = val == true ? "1" : "0";
      if (this.isAllDay) {
        (this.form.config.startMin = "00:00"),
          (this.form.config.endMin = "24:00");
      }
    },
    onChangeEmailInd(val) {
      console.log("onChangeEmailInd--val: ", val);
      //是否进行app推送告警 0：不推送 1：推送
      this.form.config.emailInd = val == true ? "1" : "0";
      // if (this.isAllDay) {
      //   (this.form.config.startMin = "00:00"),
      //     (this.form.config.endMin = "24:00");
      // }
    },
    checkSourceUniqueness(detailList) {
      const sourceSet = new Set();

      for (let detail of detailList) {
        if (sourceSet.has(detail.source)) {
          return false;
        }
        sourceSet.add(detail.source);
      }

      return true;
    },
    validateDetailList() {
      if (!this.checkSourceUniqueness(this.form.detailList)) {
        this.$message.error(this.$t("contains_duplicate_sources"));
        return false;
      }

      for (let detail of this.form.detailList) {
        const source = detail.source;
        console.log(
          "%c [ source ]-470",
          "font-size:13px; background:pink; color:#bf2c9f;",
          source
        );
        // 首先检查source是否为空
        if (!detail.source) {
          this.$message.error(this.$t("conditions_cannot_be_empty"));
          return false;
        }
        const isSourceType1248 = ["1", "2", "4", "8"].includes(source);
        console.log(
          "%c [ isSourceType1248 ]-472",
          "font-size:13px; background:pink; color:#bf2c9f;",
          isSourceType1248
        );
        const isSourceType3264 = ["32", "64"].includes(source);
        console.log(
          "%c [ isSourceType3264 ]-474",
          "font-size:13px; background:pink; color:#bf2c9f;",
          isSourceType3264
        );
        const isSourceType16 = source === "16";
        console.log(
          "%c [ isSourceType16 ]-476",
          "font-size:13px; background:pink; color:#bf2c9f;",
          isSourceType16
        );

        // 根据source值进行不同的验证
        if (isSourceType1248) {
          // 需要 type、source、min、max 都有值（包括 0）
          if (
            !detail.type ||
            !detail.source ||
            detail.min === undefined ||
            detail.min === null ||
            detail.min === "" ||
            detail.max === undefined ||
            detail.max === null ||
            detail.max === ""
          ) {
            this.$message.error(this.$t("conditions_cannot_be_empty"));
            return false;
          }
          if (!this.validateMinMax(detail)) {
            this.$message.error(this.$t("min_must_be_less_than_max"));
            return false;
          }
          if (
            ["2", "8"].includes(source) &&
            !this.validatePositiveValues(detail)
          ) {
            this.$message.error(this.$t("value_must_be_lager_than_zero"));
            return false;
          }
        } else if (isSourceType3264) {
          // 需要 type、source、max 都有值（包括 0）
          if (
            !detail.type ||
            !detail.source ||
            detail.max === undefined ||
            detail.max === null ||
            detail.max === ""
          ) {
            this.$message.error(this.$t("conditions_cannot_be_empty"));
            return false;
          }
          if (!this.validatePositiveValues(detail)) {
            this.$message.error(this.$t("value_must_be_lager_than_zero"));
            return false;
          }
        } else if (isSourceType16) {
          // 需要 type、source、min 都有值（包括 0）
          if (
            !detail.type ||
            !detail.source ||
            detail.min === undefined ||
            detail.min === null ||
            detail.min === ""
          ) {
            this.$message.error(this.$t("conditions_cannot_be_empty"));
            return false;
          }
          if (!this.validatePositiveValues(detail)) {
            this.$message.error(this.$t("value_must_be_lager_than_zero"));
            return false;
          }
        }
        //  else {
        //   // 如果source不在指定的范围内，则提示错误或者处理其他情况
        //   this.$message.error(this.$t("unsupported_source_type"));
        //   return false;
        // }
      }

      return true;
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (!this.form.name) {
            this.$message.error(this.$t("scene_name_cannot_be_empty"));
            return;
          }

          // if (this.form.deviceIdList.length <= 0) {
          //   this.$message.error(this.$t("at_least_select_one_device"));
          //   return;
          // }

          if (this.form.detailList.length <= 0) {
            this.$message.error(this.$t("at_least_add_one_rule"));
            return;
          }

          if (!this.validateDetailList()) {
            // 阻断进一步的操作
            return;
          }

          // 验证时间范围是否有效
          if (this.form.config.startMin && this.form.config.endMin) {
            const start = this.form.config.startMin;
            const end = this.form.config.endMin;

            if (start >= end) {
              this.$message.error(
                this.$t("end_time_cannot_be_greater_than_start_time")
              );

              return;
            }
          }

          if (this.emailInputVisible && !this.form.config.email) {
            this.$message.error(this.$t("email_cannot_be_empty"));
            return;
          }
          console.log("this.form.detailList: ", this.form.detailList);

          this.form.detailList = this.form.detailList.map((item) => {
            if (item.unit === "°C") {
              item.unit = "c";
            } else if (item.unit === "°F") {
              item.unit = "f";
            }
            return item;
          });
          let params = {
            // base64Image: this.base64Image,
            // imageUrl: this.imageUrl,
            orgId: this.orgId,
            ...this.form,
          };
          addSceneApi(params).then((r) => {
            console.log("=====", r);
            this.$message.success(this.$t("create_successful"));
            // this.dialogFormVisible = false;
            console.log(
              "%c [ this.orgId ]-585",
              "font-size:13px; background:pink; color:#bf2c9f;",
              this.orgId
            );

            if (this.orgId) {
              this.goPages("/organDetail", {
                orgId: this.orgId,
              });
              localStorage.setItem("selectedMenuItem", 5);
              this.$store.dispatch("updateSelectedMenuItem", 5);
            } else {
              this.goPages("/home/alert", {
                orgId: this.orgId,
              });
              localStorage.setItem("selectedMenuItem", 2);
              this.$store.dispatch("updateSelectedMenuItem", 2);
            }
          });
        } else {
          // console.error("Error submitting the form!");
          return false;
        }
      });
    },
    onChangeTime(val) {
      console.log("val: ", val);
      this.isAllDay = val == "allday" ? true : false;
    },
    backToCreate() {
      this.$router.go(-1);
    },
    // getBase64(file) {
    //   return new Promise(function(resolve, reject) {
    //     let reader = new FileReader();
    //     let imgResult = "";
    //     reader.readAsDataURL(file);
    //     reader.onload = function() {
    //       imgResult = reader.result;
    //     };
    //     reader.onerror = function(error) {
    //       reject(error);
    //     };
    //     reader.onloadend = function() {
    //       resolve(imgResult);
    //     };
    //   });
    // },

    // getFile(file) {
    //   // 清除之前的Base64图像
    //   this.clearUpload();
    //   this.getBase64(file.raw).then((res) => {
    //     console.log(res);
    //     this.base64Image = res;
    //   });
    // },
    // clearUpload() {
    //   // 清除已有的Base64图像
    //   this.base64Image = "";
    //   // 重置<el-upload>组件的状态
    //   this.$refs.upload.clearFiles();
    // },
    onTypeChange(rule) {
      console.log("onTypeChange---rule: ", JSON.stringify(rule));
      // 当选择的类型改变时触发
      // this.showDatePicker(rule);
      // 当第一个选择框发生变化时，检查是否选择了有效选项
      this.isFirstSelectChosen[rule.key] =
        rule.type !== null && rule.type !== undefined;

      const alarmTypeItem = this.alarmType.find(
        (item) => item.value === rule.type
      );
      console.log("alarmTypeItem: ", JSON.stringify(alarmTypeItem));
      this.getAlarmSensor(alarmTypeItem.code);
    },
    showDatePicker(rule) {
      console.log("showDatePicker---rule: ", rule);
      // 根据 rule.type 的值判断是否显示日期选择器
      return rule.type === "2";
    },
    onSourceChange(rule) {
      console.log("onSourceChange---rule: ", rule);
      // 当选择的传感器改变时触发
      // this.showTempUnitSelector(rule);
      // rule.unit = this.showTempUnitSelector(rule)
      //   ? this.setSelectedTempType
      //   : "";
      rule.unit = this.setSelectedTempType;
    },
    // showTempUnitSelector(rule) {
    //   console.log("showTempUnitSelector---rule: ", rule);
    //   // 根据 rule.source 的值判断是否显示温度单位选择器
    //   return ["1", "4"].includes(rule.source);
    // },
    onSelectionChange(event) {
      const selectedValue = event.target.value;
      console.log("选中的图片ID:", selectedValue);
      this.imageUrl = selectedValue;
      // 这里可以添加更多的逻辑处理
    },
    // showImagePreview(url) {
    //   this.previewImageUrl = url;
    //   this.isPreviewVisible = true;
    // },
    // closePreview() {
    //   this.isPreviewVisible = false;
    // },
    // handleKeydown(event) {
    //   if (event.key === "Escape") {
    //     // 监听 Esc 键
    //     this.closePreview();
    //   }
    // },
    // handleClickOutside(event) {
    //   console.log("event: ", event);
    //   const modal = event.currentTarget;
    //   console.log("modal: ", modal);
    //   if (modal.contains(event.target)) {
    //     this.closePreview();
    //   }
    // },
    showDurationSelector(rule) {
      console.log("rule: ", JSON.stringify(rule));
      // console.log("showTempUnitSelector---rule: ", rule);
      // 根据 rule.source 的值判断是否显示温度单位选择器
      return ["2"].includes(rule.type);
    },
    showOrTextSelector(rule) {
      // console.log("showTempUnitSelector---rule: ", rule);
      // 根据 rule.source 的值判断是否显示温度单位选择器
      return ["1", "2", "4", "8"].includes(rule.source);
    },
    //电量要隐藏or more than,震动喝光照要隐藏less than or
    showAfterOrSelector(rule) {
      return ["32", "64"].includes(rule.source);
    },
    //电量要隐藏or more than,震动喝光照要隐藏less than or
    showBeforeOrSelector(rule) {
      return ["16"].includes(rule.source);
    },
    showTemperatureUnit(rule) {
      console.log("showTemperatureUnit---rule: ", JSON.stringify(rule));
      // 根据 rule.source 的值判断是否显示温度单位选择器
      return ["2", "8", "16", "32", "64"].includes(rule.source);
    },
    calculateWidth(length) {
      return 100 / length;
    },
    validateMinMax(rule) {
      if (
        rule.min !== undefined &&
        rule.min !== null &&
        rule.min !== "" &&
        rule.max !== undefined &&
        rule.max !== null &&
        rule.max !== ""
      ) {
        return Number(rule.min) < Number(rule.max);
      }
      return true;
    },
    validatePositiveValues(rule) {
      //湿度，外部湿度，电量，光照，震动
      const positiveValueSources = ["2", "8", "16", "32", "64"];
      if (positiveValueSources.includes(rule.source)) {
        if (rule.min !== undefined && rule.min !== null && rule.min !== "") {
          return Number(rule.min) > 0;
        }
        if (rule.max !== undefined && rule.max !== null && rule.max !== "") {
          return Number(rule.max) > 0;
        }
      }
      return true;
    },
  },
  props: {},
  components: {
    AddDeviceToNewAlertDialog,
  },
  computed: {
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
    setSelectedTempType() {
      return this.$store.getters.selectedTempType;
    },
  },
  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },

  mounted() {
    this.sceneId = this.$route.query.sceneId;
    this.orgId = this.$route.query.orgId;
    console.log(
      "%c [ this.orgId ]-779",
      "font-size:13px; background:pink; color:#bf2c9f;",
      this.orgId
    );

    console.log("this.sceneId: ", this.sceneId);
    this.$i18n.locale = this.setSelectedLanguage;
    document.addEventListener("keydown", this.handleKeydown);
    this.getAlarmType();
    this.getAlarmSensor();
    // this.getAlarmConditions();
    this.getSceneAlarmConfig();
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKeydown);
  },
  created() {},
};
</script>

<style scoped lang="less">
.container {
  padding: 16px 47px 70px 30px;
  .title {
    font-size: 36px;
    font-weight: 500;
    color: #262626;
    line-height: 51px;
    height: 51px;
  }
  .sub-title {
    font-size: 14px;
    color: #262626;
    margin-top: 3px;
    line-height: 20px;
    height: 20px;
  }

  .basic-settings {
    background: white;
    margin-top: 14px;
    padding: 17px 19px 0 19px;
    border-radius: 8px;
    .title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      line-height: 39px;
      height: 39px;
      font-size: 28px;
      color: #262626;

      img {
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin-right: 97px;
      }
    }
    .input-style {
      width: 883px;

      /deep/.el-input__inner {
        line-height: 54px;
        height: 54px;
      }
    }

    .sub-title {
      font-weight: 500;
      font-size: 18px;
      margin: 10px 0;
      line-height: 26px;
      height: 26px;
      color: #262626;
    }

    .sub-tip {
      color: #848484;
      font-size: 16px;
      // margin-top: 5px;
    }
    .add-rule {
      width: 163px;
      background: #1972f4;
      height: 35px;
      line-height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px 0 30px 0;
    }

    .radio {
      margin: 10px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      .custom-time {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
    .checkbox {
      display: flex;
      flex-direction: row;
      align-items: center;

      .el-input {
        width: 200px;
        margin-left: 20px;
      }
    }
    .rule-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border: 1px solid rgba(187, 187, 187, 1);
      padding: 5px 10px;
      height: 49px;
      line-height: 49px;
      margin-right: 50px;
      border-radius: 8px;
      margin-left: -120px;
      img {
        width: 33px;
        height: 33px;
      }
      .selects-container {
        display: flex;
        gap: 8px;
        align-items: center;
        .select-item {
          width: 220px;
          height: 47px;
          line-height: 47px;
          /deep/.el-input__inner {
            background: #f4f9ff;
          }
        }
        .input-item {
          width: 100px;
          height: 47px;
          line-height: 47px;
          /deep/.el-input__inner {
            background: #f4f9ff;
          }
        }
      }
    }

    /* 自定义 el-radio 的样式 */
    .radio .el-radio {
      background-color: #f4f9ff;
      border: 1px solid #bbbbbb;
      height: 38px;
      line-height: 38px; /* 对齐文本垂直居中 */
      font-size: 18px;
      color: #848484; /* 文本颜色设为灰色 */
      border-radius: 8px;
      padding: 0 10px;
      // text-align: center;

      /* 调整内部元素的样式 */
      /deep/.el-radio__label {
        line-height: 38px; /* 对齐文本垂直居中 */
        font-size: 18px;
        color: #848484; /* 文本颜色设为灰色 */
      }

      /deep/.el-radio__inner {
        width: 18px;
        height: 18px;
        border: 1px solid #101010;
        background-color: #f4f9ff;
      }

      /* 调整勾选状态下的样式 */
      /deep/.is-checked .el-radio__inner {
        background-color: #101010;
        border: 1px solid #101010;
        color: #848484;
      }

      /* 调整勾选状态下的图标 */
      .is-checked .el-radio__inner::after {
        width: 8px;
        height: 4px;
        transform: rotate(45deg);
        background-color: #fff;
        content: "";
        position: absolute;
        top: 6px;
        left: 5px;
      }

      .el-radio__input .is-checked .el-radio__label {
        color: #848484; /* 文本颜色设为灰色 */
      }
    }

    /* 调整内部元素的样式 */
    /deep/.el-checkbox__label {
      line-height: 38px; /* 对齐文本垂直居中 */
      font-size: 18px;
      color: #848484;
      font-size: 16px;
    }
  }

  .select-device {
    // position: relative;
    display: flex;
    flex-direction: column;
    .select-box {
      width: 883px;
      line-height: 54px;
      height: 54px;
      border: 1px solid #dcdfe6;
      border-radius: 4px;

      margin-top: 5px;
      margin-bottom: 5px;
      display: flex;
      align-items: center; /* 垂直居中 */
      justify-content: space-between; /* 水平居中 */
      span {
        padding: 0 10px;
        color: #848484;
      }
      img {
        width: 24px;
        height: 24px;
        margin-right: 9px;
      }
    }
    //   .select-style {
    //     width: 883px;
    //     // z-index: -1;
    //     // position: absolute;
    //     /deep/.el-input__inner {
    //       line-height: 54px;
    //       height: 54px;
    //       background: white;
    //       margin-top: 5px;
    //       margin-bottom: 5px;
    //     }
    //   }

    //   /* 自定义下拉箭头 */
    //   /deep/.el-select .el-input .el-select__caret {
    //     visibility: hidden; /* 隐藏默认的下拉箭头 */
    //   }

    //   /* 自定义下拉箭头 */
    //   /deep/.el-input__suffix {
    //     display: flex;
    //     align-items: center; /* 垂直居中 */
    //     justify-content: center; /* 水平居中 */
    //     /* 如果需要，可以调整内边距以适应图标 */
    //     // padding: 0 8px;
    //   }

    //   /deep/.el-input__suffix-inner {
    //     background-image: url("../../assets/ic_blue_arrow.png");
    //     background-size: cover;
    //     background-position: center;
    //     background-repeat: no-repeat;
    //     font-family: ""; /* 清空字体家族 */
    //     content: ""; /* 清空内容 */
    //     width: 24px; /* 图片宽度 */
    //     height: 24px; /* 图片高度 */
    //   }
  }
  .create-btn {
    width: 135px;
    background: #1972f4;
    margin-top: 20px;
    margin-bottom: 122px;
  }
  .upload-box {
    display: flex;
    flex-direction: row;
    align-items: center;

    .sub-title {
      font-weight: 500;
      font-size: 18px;
      margin: 10px 0;
      line-height: 26px;
      height: 26px;
      color: #262626;
    }
  }
  .image-container {
    max-width: 100%;
    height: 230px;
    margin-right: 20px;
    .bg {
      width: 100%;
      height: 100%;
      position: relative;
      object-fit: cover;
    }
  }

  // .image-container img {
  //   max-width: 100%;
  //   height: 100%;
  //   position: relative;
  //   object-fit: cover;
  // }
  .h_line {
    background: #dcdfe6;
    height: 1px;
    width: 100%;
    margin: 20px 0;
  }
  .bg-img-box {
    display: flex;
    flex-direction: column;
    img {
      margin: 10px 10px 10px 0;
    }
  }

  .preview-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    .close {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active 在 Vue 2.x 中 */ {
    opacity: 0;
  }
  /deep/.el-select .el-input.is-disabled .el-input__inner {
    color: #606266;
  }
  /deep/.el-input.is-disabled .el-input__inner {
    color: #606266;
  }
  .custom-placeholder /deep/.el-input__inner::-webkit-input-placeholder {
    color: #606266;
  }
  .custom-placeholder /deep/.el-input__inner:-ms-input-placeholder {
    color: #606266;
  }
  .custom-placeholder /deep/.el-input__inner::placeholder {
    color: #606266;
  }
}
</style>
